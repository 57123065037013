import { Register } from "src/components/Register";
import { Collaborations } from "./Collaborations";

export const RegisterSection = () => (
  <article className="flex flex-col items-center justify-center padding Background shadow-3xl rounded-3xl max-container">
    <h1 className="title-text text-center max-lg:text-lg">
      Explore our test datasets for free{" "}
      <div className="gradient-text">(now and always).</div>
    </h1>
    <p className="info-text text-center">
      Enter your email to receive an invitation and get started.
    </p>
    <Register />
    <Collaborations/>
  </article>
);