import React from 'react';
import ReactDOM from 'react-dom/client';
import 'src/index.css';
import App from 'src/App';
import { ScrollToTop } from './components/ScrollToTop';
import { HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <ScrollToTop/>
      <App/>
    </HashRouter>
  </React.StrictMode>
);
