import { useState } from "react";
import { Modal } from "src/components/Modal";
import { Loading } from "src/components/Loading";

export const Register = () => {
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    message: "",
  });

  const openModal = (title, message) => {
    setModalContent({ title, message });
    setIsModalOpen(true);
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    if (email) {
      setLoading(true);
      let response = await fetch("https://kwiipsqjrt6mv5u6hqe6fli2le0tnxno.lambda-url.eu-north-1.on.aws/", {
        method: "POST",
        headers: {
          "X-Require-Whisk-Auth": "dbEn3CLR8SWEL8P9P50IF3XZ0gTZQBc1sDUM+hCCny8=",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          //isError: true //Uncomment to get unsuccessful response on localhost:3000
        })
      });
      // set error when response unsuccessful
      if (!response.ok) {
        openModal("Ooops!", "There was an error. Please try again.");
        setLoading(false);
      } else {
        openModal("Success!", "Check your inbox and click on the link in the invitation to setup your Synapp account. If you can not find your invitation email in your normal inbox, it is worth checking in your spam or junk mail folders.");
        setLoading(false);
      }
    }
  };

  return (
    <div className="w-full max-w-md max-lg:max-w-xs" id="Register">
      <form className="rounded pt-6 pb-8 mb-4">
        <div className="mb-4">
          <input
            className="shadow appearance-none rounded w-full py-2 px-3 text-gray leading-tight focus:outline-none focus:shadow-outline"
            id="Email"
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            className="bg-transparant hover:bg-orange hover:text-white text-black focus:outline-none focus:shadow-outline flex justify-center items-center gap-2 px-7 py-3 border text-base max-lg:text-xs border-orange font-lexendgiga leading-none rounded-full"
            onClick={handleRegister}
          >
            Submit
          </button>
        </div>
      </form>
      { loading && <Loading isloading={loading} /> }
      <Modal
        title={modalContent.title}
        message={modalContent.message}
        isOpen={isModalOpen}
        onClose={()=>{setIsModalOpen(false)}}
      />
    </div>
  );
};
