export const TestingIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 190 190"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.945 167.799C134.989 162.431 154.245 146.313 163.614 124.644L165.162 121.066L172.319 124.16L170.772 127.738C160.228 152.124 138.347 170.141 112.254 175.718L112.809 190L88.7506 173.38L111.443 154.936L111.945 167.799ZM14.2806 112.254L0 112.809L16.6212 88.7506L35.0634 111.443L22.2018 111.945C27.5682 134.989 43.6864 154.245 65.356 163.614L68.9342 165.162L65.8397 172.319L62.2606 170.772C37.8767 160.228 19.8585 138.347 14.2806 112.254ZM167.799 78.0555C162.431 55.0118 146.313 35.756 124.644 26.3855L121.066 24.8383L124.16 17.681L127.738 19.2292C152.124 29.7732 170.141 51.6544 175.718 77.7468L190 77.1898L173.38 101.249L154.936 78.5566L167.799 78.0555ZM77.7468 14.2806L77.1898 0L101.249 16.6212L78.5566 35.0634L78.0555 22.2018C55.0118 27.5682 35.756 43.6864 26.3855 65.356L24.8383 68.9342L17.681 65.8397L19.2292 62.2606C29.7732 37.8767 51.6544 19.8585 77.7468 14.2806Z"
      fill="black"
      strokeWidth="2px"
    />
  </svg>
);
