import { RightIcon } from "src/assets/icons/RightIcon";
import { PricingCards } from "src/constants";
import { Button } from "src/components/Button";

export const PricingCard = () => {
  const scrollToSection = () => {
    const section = document.getElementById("Register");
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <div className="flex-view flex-col">
      <div className="flex flex-wrap items-center justify-center justify-items-center gap-10 max-lg:max-w-sm max-lg:px-5">
        {PricingCards.map((item) => (
          <div
            key={item.title}
            className="flex flex-col flex-grow mt-8 overflow-hidden bg-white rounded-xl shadow-3xl hover:scale-110 w-72"
          >
            <div className="flex flex-col items-center p-6 bg-lite-pink info-text leading-6">
              <h1 className="font-semibold text-3xl text-pitch-black">
                {item.title}
              </h1>
              <span className="text-5xl font-bold pb-1">{item.cost}</span>
              <Button
                label={item.buttonLabel}
                bgColor="white"
                link={item.buttonlink}
                onClick={
                  item.buttonLabel === "Get Started" ? scrollToSection : null
                }
              />
            </div>
            <div className="p-5">
              <div className="h-24">
                <h1 className="font-bold text-orange">Services</h1>
                {item.Services.map((service) => (
                  <li key={service.name} className="flex items-center">
                    <RightIcon />
                    <span className="ml-2 text-pitch-black font-raleway text-sm">
                      {service.name}
                    </span>
                  </li>
                ))}
              </div>
              <div className="h-24">
                <h1 className="font-bold text-orange p-1">Usage</h1>
                {item.Usage.map((service) => (
                  <li key={service.name} className="flex items-center">
                    <RightIcon />
                    <span className="ml-2 text-pitch-black font-raleway text-sm">
                      {service.name}
                    </span>
                  </li>
                ))}
              </div>
              <div className="h-24">
                <h1 className="font-bold text-orange p-1">Implementation</h1>
                {item.Implementation.map((i) => (
                  <li key={i.name} className="flex items-center h-30">
                    <RightIcon />
                    <span className="ml-2 text-pitch-black font-raleway text-sm">
                      {i.name}
                    </span>
                  </li>
                ))}
              </div>
              <div className="h-24">
                <h1 className="font-bold text-orange p-1">Support</h1>
                {item.Support.map((i) => (
                  <li key={i.name} className="flex items-center">
                    <RightIcon />
                    <span className="ml-2 text-pitch-black font-raleway text-sm">
                      {i.name}
                    </span>
                  </li>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex-col flex-grow  max-lg:px-5">
        <div className="bg-white rounded-xl shadow-3xl hover:scale-110 max-lg:mt-20 mt-20">
          <div className="p-6 bg-lite-pink info-text leading-6 m-auto text-center">
            <h1 className="font-semibold text-3xl text-pitch-black text-center">
              Data Orientation
            </h1>
            <span className="text-5xl font-bold pb-1 text-center">
              included in Enterprise, billable by hour or project for Basic &
              Pro
            </span>
            <div className="flex-view">
              <Button
                label="Contact us"
                bgColor="white"
                link="https://syndataab.pipedrive.com/scheduler/1mD4O3Tb/learn-more-about-data-orientation"
              />
            </div>
          </div>
          <ul className="p-5 my-4">
            <li className="flex">
              <RightIcon />
              <span className="flex-1 ml-2 text-left text-pitch-black font-raleway text-sm">
                Not sure where to start, start here
              </span>
            </li>
            <li className="flex text-left">
              <RightIcon />
              <span className="flex-1 ml-2  text-pitch-black font-raleway text-sm">
                Identify your needs, understand your structure, document your
                data flows, blueprint your process
              </span>
            </li>
            <li className="flex pb-5">
              <RightIcon />
              <span className="flex-1 ml-2 text-pitch-black font-raleway text-sm text-left">
                Pure assessment, no commitment required
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
