import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Footer } from "src/components/Footer";
import { Navigation } from "src/components/Navigation";
import { Home } from "src/pages/home/Home";
import { About } from "src/pages/about/About";
import { Pricing } from "src/pages/Pricing";
import { UseCasesPage } from "src/pages/UseCasesPage";
import { SolutionsPage } from "src/pages/SolutionsPage";
import { Testimonials } from "src/pages/Testimonials";
import { PrivacyPolicy } from "src/pages/PrivacyPolicy";
import { Contact } from "src/pages/Contact";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    return () => {
      AOS.refresh();
    }
  }, []);

  return (
    <div className="Background">
      <div className="FadeEffect">
        <Navigation />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/usecases" element={<UseCasesPage />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/solutions" element={<SolutionsPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/contactus" element={<Contact/>} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}
export default App;
