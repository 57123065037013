import { useState } from "react";
import { Card } from "src/components/Card";
import { useCases } from "src/constants";

export const UseCases = () => {
  const [selectedUseCase, setSelectedUseCase] = useState(useCases[0]);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const handleCardClick = (usecase, index) => {
    setSelectedUseCase(usecase);
    setHighlightedIndex(index);
  };
  // Filter the usecases array to create a new array with only the selected use case
  const filteredUseCases = selectedUseCase ? [selectedUseCase] : [];
  return (
    <section className="rounded-3xl">
      <header className="text-center flex flex-col padding-y">
        <h1 className="title-text text-xl">
          What’s Your <span className="gradient-text"> Data</span> Problem?
        </h1>
      </header>
      <section className="flex-view gap-6 max-lg:gap-2 max-lg:pb-5 flex-wrap">
        {useCases.map((usecase, index) => (
          <div
            key={index}
            onClick={() => handleCardClick(usecase, index)}
            className={`flex-shrink hover:bg-lite-orange rounded-3xl ${
              index === highlightedIndex ? "bg-lite-orange animate-fadeI" : ""
            }`}
          >
            <Card
              {...usecase}
              highlightedIndex={highlightedIndex}
              selectedUseCase={selectedUseCase}
            />
          </div>
        ))}
      </section>
      <div className="mx-20 my-10 max-lg:m-0 padding-x max-lg:p-0 overflow-scroll overflow-x-hidden h-[400px] max-lg:h-[500px] hover:FadeEffect rounded-3xl">
        {filteredUseCases.map((usecase) => (
          <div key={usecase.label}>
            <p className="font-raleway text-black text-2xl leading-10 max-lg:text-xl text-center mt-6 py-5">
              {usecase.description}
            </p>
            <div className="">
              {usecase.Questions.map((question, index) => {
                if (index % 2 === 0)
                  return (
                    <article
                      key={question.questionText}
                      className="flex-view max-lg:flex-col-reverse  gap-10 max-lg:gap-1 rounded-3xl bg-lite-pink p-4"
                    >
                      <div className="flex-view max-lg:flex-1 flex-shrink text-center flex-col m-3">
                        <h2 className="font-lexendgiga capitalize text-xl lg:max-w-xl font-bold text-center max-lg:text-base">
                          {question.questionText}
                        </h2>
                        <p className="mt-4 lg:max-w-xl info-text text-center max-lg:text-sm">
                          {question.questionAnswer}
                        </p>
                      </div>
                      <div className="flex-view">
                        <img
                          src={question.questionImage}
                          alt="Solutions"
                          width={370}
                          height={222}
                          className="w-48 h-48 max-lg:w-full object-contain rounded-3xl"
                        />
                      </div>
                    </article>
                  );
                else
                  return (
                    <article
                      key={question.questionText}
                      className="flex-view max-lg:flex-col gap-10 max-lg:gap-1 rounded-3xl p-2"
                    >
                      <div className="flex-view">
                        <img
                          src={question.questionImage}
                          alt="Solutions"
                          width={370}
                          height={222}
                          className="w-48 h-48 max-lg:w-full object-contain rounded-3xl"
                        />
                      </div>
                      <div className="flex-view text-center flex-col">
                        <h2 className="font-lexendgiga capitalize text-xl lg:max-w-xl font-bold text-center max-lg:text-base">
                          {question.questionText}
                        </h2>
                        <p className="mt-4 lg:max-w-xl info-text text-center max-lg:text-sm">
                          {question.questionAnswer}
                        </p>
                      </div>
                    </article>
                  );
              })}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
