export const EncryptedIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_447_163)">
      <path
        d="M142.612 77.1602L126.191 93.6204C126.775 95.7828 127.095 98.0465 127.095 100.394C127.095 114.556 115.617 126.045 101.444 126.045C99.1309 126.045 96.8547 125.729 94.7212 125.141L85.2893 134.587C90.5632 135.883 96.0677 136.648 101.75 136.648C140.693 136.648 172.266 100.83 172.266 100.83C172.266 100.83 160.547 87.5604 142.612 77.1602Z"
        fill="#231F20" stroke="#000000" strokeWidth="0.5px"
      />
      <path
        d="M57.01 122.157L75.948 103.206C75.8448 102.29 75.8072 101.35 75.8072 100.397C75.8072 86.228 87.2723 74.7494 101.444 74.7494C102.398 74.7494 103.352 74.7996 104.253 74.9018L113.15 66.0177C109.439 65.3696 105.625 65.0137 101.748 65.0137C62.7903 65.0137 31.2056 100.831 31.2056 100.831C31.2056 100.831 41.2596 112.229 57.01 122.157Z"
        fill="#231F20" stroke="#000000" strokeWidth="0.5px"
      />
      <path
        d="M99.9063 -0.0537109C44.7906 -0.0537109 -0.0483398 44.791 -0.0483398 99.9135C-0.0483398 155.036 44.7925 199.881 99.9063 199.881C155.029 199.881 199.873 155.034 199.873 99.9135C199.873 44.7929 155.029 -0.0537109 99.9063 -0.0537109ZM99.9063 181.163C81.4225 181.163 64.0084 175.105 49.5427 163.644L47.251 161.828L161.816 47.264L163.633 49.5537C175.096 64.0213 181.153 81.4394 181.153 99.9174C181.152 144.716 144.706 181.163 99.9063 181.163ZM99.9063 18.6741C118.287 18.6741 135.635 24.6714 150.069 36.0198L152.378 37.835L37.8211 152.379L36.0068 150.068C24.6585 135.615 18.6602 118.271 18.6602 99.9183C18.6602 55.1209 55.1069 18.6741 99.9063 18.6741Z"
        fill="#231F20" stroke="#000000" strokeWidth="0.5px"
      />
    </g>
    <defs>
      <clipPath id="clip0_447_163">
        <rect width="200" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
