import { PricingCard } from "src/components/PricingCard";
import { RegisterSection } from "./home/sections/RegisterSection";

export const Pricing = () => (
  <section className="max-container padding">
    <section className="flex-view flex-col min-h-screen p-10">
      <h2 className="title-text text-center pb-10">Pricing</h2>
      <PricingCard />
    </section>
    <RegisterSection/>
  </section>
);
