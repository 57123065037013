import { colorVariants } from "src/constants";

export const Button = ({ label, bgColor, link, ...props }) => (
  <a
    className={`${colorVariants[bgColor]}
      flex justify-center
      items-center
      gap-2 px-7 py-3
      border
      max-lg:text-xs
      text-base border-orange font-lexendgiga leading-none rounded-full hover:cursor-pointer shadow-3xl max-lg:py-2 max-lg:px-6`
    }
    href={link}
    {...props}
  >
    {label}
  </a>
);
