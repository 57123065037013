import { collaborationsLogos } from "src/constants";

export const Collaborations = () => (
  <section className="">
    <section className="flex justify-around gap-6 max-lg:justify-center max-lg:gap-3 justify-items-center flex-wrap ">
      {collaborationsLogos.map((item) => (
        <div key={item.imgURL} className="hover:animate-bounce">
          <img
            src={item.imgURL}
            alt="Collaboration Logo"
            className="items-center object-contain w-20 h-20 p-2 max-lg:w-16 max-lg:h-16"
          />
        </div>
      ))}
    </section>
  </section>
);
