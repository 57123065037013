export const PrivacyPolicy = () => (
  <div className="max-container padding max-lg:p-0">
    <div className="justify-center items-center">
      <div className="Background text-center shadow-3xl rounded-3xl m-auto">
      <h1 className="title-text text-center  max-sm:text-[60px] max-sm:leading-[72px] max-lg:text[20px] padding-t" >
        Smart and Safe Way to Unlock Data
      </h1>
        <p className="font-raleway text-black text-2xl leading-7 max-lg:text-xl  flex- m-auto text-center  px-20  pb-20">
          {" "}
          Because privacy and security are at the heart of what we do.
        </p>
      </div>
      <article>
        <h1 className="title-text text-2xl font-bold text-pitch-black padding-x py-6">
          Privacy Policy
        </h1>
        <div className="padding-x font-raleway">
          Syndata AB, company registration number 559241-3867, with the
          registered address at Roslagsgatan 13, 113 55 Stockholm, Sweden
          (“Syndata” “we” or “us”). This document contains a statement regarding
          our collection, use and processing of personal data (“Privacy
          Notice”). This Privacy Notice is applicable when you use the Syndata
          website https://syndata.co or sign up for our newsletter. Definitions
          in this Privacy Notice shall be interpreted in accordance with
          applicable data protection laws which refers to the General Data
          Protection Regulation (Regulation no. 2016/679) and the Directive on
          Privacy and Electronic Communications (Directive 2002/58/EC), as well
          as the national implementations and related national legislation.
          <p className="text-orange py-2">
            2. Processing and collection of personal data
          </p>
          We process the following personal data about you: • Identity and
          contact information, including your name, e-mail address, address,
          telephone number; • Business related information, including
          information provided in the course of the contractual or client
          relationship between you or your organisation and us, or otherwise
          voluntarily provided by you or your organisation, and other personal
          data concerning your preferences relevant to the service(s) for which
          you hired us/delivery of the product you have purchased from us; •
          Preferences in receiving information, including your name, e-mail
          address, address, telephone number, employment, job title and
          function, and other personal data concerning your preferences relevant
          to informational services. We collect the personal data in the
          following circumstances: • When you or your organisation wants to buy
          products/services, from us; • When you or your organisation provides,
          or offer to provide, products/services to us; • When you correspond
          with us by e-mail or other electronical or physical means, by phone or
          directly face-to-face; • When you or your organisation makes a request
          or otherwise interacts with our web page; • When you attend our events
          or sign up to receive other information from us; • When making
          requests from your organisation or other organisations where you work,
          or from third party sources such as publicly available records. We
          might use your personal data for the following purposes (exhaustive
          list): • To maintain adequate records of you • To deliver,
          administrate and communicate our products/services with you • To
          administer and manage communication with you, including subscriptions
          to newsletters or other information services • To administer and
          manage your participation at our courses, seminars or other events •
          To provide you with information and offers related to our
          products/services
          <p className="text-orange py-2">3. Legal grounds</p>
          Consent. When you visit our website or sign up for our newsletter, you
          consent to the processing of the personal data as set out in this
          Privacy Notice. Legitimate interest. The processing of your personal
          data is conducted based on our legitimate interest of developing and
          improving our services and business as well as our website. Contract:
          When signing a contract, we will process the data in order to perform
          in accordance with the contract. Legal obligations: We will process
          your personal data if it is necessary to comply with our legal
          obligations such as to keep records of our accounting or taxation
          purposes.
          <p className="text-orange py-2">4. Disclosure of personal data</p>
          We will not sell your personal data to any third parties, but we will
          share them with parties that can help us in our business or with the
          purpose for which we process your personal data. That is for example
          our business partners and service providers. Your information may be
          transferred outside the EU/EEA as some of our service providers are
          located outside EU/EEA, but only to a country deemed adequate by the
          European Commission or if we have a transfer mechanism in place in
          accordance with the legislation of the European Union, such as the EU
          Model Clauses.
          <p className="text-orange py-2">
            5. Data retention and responding to legal requests
          </p>
          We store your personal data as long as necessary in order to fulfil
          the purposes for which it was collected according to this Privacy
          Notice or as long as we have legal grounds to do so. That is most
          often for as long as you are one of our clients but can also be 7
          years according to tax and accounting regulation. We may access,
          preserve and share your personal data in response to a legal request
          (like a search warrant, court order, subpoena, or the like), or when
          necessary to detect, prevent and address fraud and other illegal
          activity, to protect ourselves, you and other users, including as part
          of investigations.
          <p className="text-orange py-2">6. Intended use</p>
          Syndata offer services to businesses and not to individuals and
          consumers. The services of Syndata are not directed to private
          persons.
          <p className="text-orange py-2">7. Links to other sites</p>
          When using the Syndata website or newsletter or our services you may
          be directed to other websites where your personal data collected is
          outside of our control. The privacy notices of the other websites will
          govern the personal data obtained from you on that website.
          <p className="text-orange py-2">8. Your rights</p>
          You have an absolute right to object to the processing of your
          personal data for direct marketing. You also have the right to recall
          your prior given consent. The withdrawal of your consent does not
          affect the lawfulness of the processing based on the consent before
          its withdrawal, and we may continue processing your personal data
          based on other legal grounds, except for direct marketing. You have
          the right to request access and further information concerning the
          processing of your personal data, or request that we correct, rectify,
          complete, erase or restrict the processing of your personal data.
          <p className="text-orange py-2">9. Contact information</p>
          To exercise your rights regarding your personal data, or if you have
          any questions regarding our processing of your personal data, please
          contact us at the following email info@syndata.co or by letter
          addressed to our postal address as set out above. If you have
          complaints regarding our processing of your personal data, you may
          file a complaint to the competent data protection authority. You can
          find out more about the local data protection authorities under the
          following link
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
          This privacy notice is valid from 2021-08-18
        </div>
      </article>
    </div>
  </div>
);