import { solutionsItems } from "src/constants";

export const Solutions = () => (
  <section className="max-container">
    <h1 className="title-text text-center padding-y ">How Do We Solve It?</h1>
    {solutionsItems.map((item, index) => {
      if (index % 2 === 0)
        return (
          <article key={item.label} className="flex-view max-lg:flex-col-reverse gap-10 max-lg:gap-2 bg-lite-pink rounded-3xl p-4">
            <div className="flex-view flex-col">
              <h2 className="font-lexendgiga capitalize text-xl lg:max-w-xl font-bold text-center max-lg:text-base">
                {item.label}
              </h2>
              <p className="mt-4 lg:max-w-xl info-text text-center max-lg:text-sm">
                {item.text}
              </p>
            </div>
            <div data-aos="fade-down" className="flex-view">
              <img
                src={item.Image}
                alt="Solutions"
                width={400}
                height={300}
                className="object-contain h-[300px] w-[400px] max-lg:w-full max-lg:h-[250px]  p-1 rounded-3xl"
              />
            </div>
          </article>
        );
      else
        return (
          <article key={item.label} className="flex-view max-lg:flex-col gap-10 max-lg:gap-2 rounded-3xl p-4">
            <div data-aos="fade-up" className="flex-view">
              <img
                src={item.Image}
                alt="Solutions"
                width={400}
                height={300}
                className="object-contain h-[300px] w-[400px]  max-lg:h-[250px]  p-1 rounded-3xl"/>
            </div>
            <div className="flex-view flex-col">
              <h2 className="font-lexendgiga capitalize text-xl lg:max-w-xl font-bold text-center max-lg:text-base">
                {item.label}
              </h2>
              <p className="mt-4 lg:max-w-xl info-text text-center max-lg:text-sm">
                {item.text}
              </p>
            </div>
          </article>
        );
    })}
  </section>
);
