import SyndataLogo from "src/assets/logo/logo-syndata.svg";

export const Loading = ({ isloading }) => (
       isloading && (
        <div className="flex-view h-screen fixed inset-0  z-10 overflow-auto">
          <div className="modal-overlay bg-gray opacity-50 fixed inset-0"></div>
          <div className=" flex-view rounded-full h-32 w-32 ">
            <img className="flex-view p-2 animate-ping" src={SyndataLogo} alt=""/>
          </div>
        </div>
      )
  );
