import { Link } from "react-router-dom";
import { footerLinks, socialMedia } from "src/constants";
import Logo from "src/assets/logo/logo-syndata.svg";

export const Footer = () => (
  <footer className="background-logo padding-x padding-t pb-8 bottom-0">
    <div className="flex justify-between max-lg:justify-center items-center gap-20 flex-wrap">
      <div className="flex flex-col mt-32 max-lg:hidden">
        <Link to="/">
          <img src={Logo} alt="logo" width={150} height={46} className="m-0" />
        </Link>
      </div>
      <div className="flex flex-1 flex-row justify-end max-lg:justify-center lg:gap-10 gap-10 flex-wrap max-w-sm">
        {footerLinks.map((section) => (
          <div key={section.title}>
            <h4 className="mt-10 font-lexendgiga text-base leading-normal font-medium mb-6 text-orange max-lg:text-sm">
              {section.title}
            </h4>
            <ul>
              {section.links.map((link) => (
                <li key={link.name} className="flex max-lg:flex-row mt-3 font-raleway text-xs leading-normal text-pitch-black 0 hover:text-orange text-center">
                  <Link to={link.link}>{link.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
    <div className="flex justify-between text-gray mt-12 max-sm:flex-col max-sm:items-center border-t-2">
      <div className="flex flex-1 justify-start items-center gap-2 font-lexendgiga text-xs cursor-pointer">
        <p>© {new Date().getFullYear()} All Rights Reserved | Syndata</p>
      </div>
      <div className="flex-view gap-5">
        {socialMedia.map((icon) => (
          <div className="flex-view" key={icon.alt}>
            <a className="p-1" target="_blank" rel="noreferrer" href={icon.link}>
              {icon.src}
            </a>
          </div>
        ))}
      </div>
    </div>
  </footer>
);
