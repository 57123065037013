import { Header } from "src/pages/home/sections/Header";
import { UseCases } from "src/pages/home/sections/UseCases";
import { Solutions } from "src/pages/home/sections/Solutions";
import { RegisterSection } from "./sections/RegisterSection";

export const Home = () => (
  <main className="relative">
    <section className="FadeEffect">
      <Header />
    </section>
    <section className="padding-b">
      <UseCases />
    </section>
    <section className="padding-x">
      <RegisterSection/>
    </section>
    <section className="padding-x w-full">
      <Solutions />
    </section>
  </main>
);
