import { useState } from "react";
import { PhoneIcon } from "src/assets/icons/PhoneIcon";
import { EmailIcon } from "src/assets/icons/EmailIcon";
import { Modal } from "src/components/Modal";
import { Loading } from "src/components/Loading";

export const Contact = () => {
  const [contactUsForm, setContactUsForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    message: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState({
    nameError: false,
    emailError: false,
    messageError: false,
  });
  const [loading, setLoading] = useState(false);
  //Modal's functions
  const openModal = (title, message) => {
    setModalContent({ title, message });
    setIsModalOpen(true);
  };

  const isValidForm = () => {
    // get the email input
    var emailInput = document.getElementById("email");
    var invalidInput = false;
    if (!contactUsForm.name) {
      setErrors((prevState) => ({
        ...prevState,
        nameError: true,
      }));
      invalidInput = true;
    }
     // valid True/False if the email match or not
    if (!emailInput.validity.valid) {
      setErrors((prevState) => ({
        ...prevState,
        emailError: true,
      }));
      invalidInput = true;
    }
    if (!contactUsForm.message) {
      setErrors((prevState) => ({
        ...prevState,
        messageError: true,
      }));
      invalidInput = true;
    }
    if (!invalidInput) return true;
    else return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidForm()) {
      setLoading(true);
      let response = await fetch(
        "https://ptbwgukqksc76cta2biksem5te0inzmg.lambda-url.eu-north-1.on.aws/",
        {
          method: "POST",
          headers: {
            "X-Require-Whisk-Auth": "EbrEXI5n0Gs2nDTYGyHoWLHpmn7WkdX9drphbltkpzg=",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: contactUsForm.name,
            email: contactUsForm.email,
            message: contactUsForm.message,
            //localhost_email_to: 'xxx@syndata.co', //Uncomment to test response on localhost:3000
          }),
        }
      );
      // set error when response unsuccessful
      if (!response.ok) {
        openModal("Ooops!", "There was an error. Please try again.");
        setLoading(false);
      } else {
        openModal(
          "Success!",
          "Thanks for contacting us, we'll reply as soon as possible."
        );
        setIsDisabled(true);
        setErrors(false);
        setLoading(false);
      }
    }
  };
  return (
    <div className="padding max-container">
      <div className="flex-view flex-col">
        <div className="text-center w-full">
          <h1 className="font-lexendgiga title-text padding-t">Contact Us</h1>
        </div>
        <form
          onSubmit={handleSubmit}
          className="max-w-screen-xl mt-24 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto  bg-syndata-logo bg-repeat text-black rounded-3xl shadow-lg"
        >
          <div className="flex flex-col justify-between">
            <div className="flex flex-col justify-start items-start justify-items-center padding-t max-lg:pt-0">
              <h2 className="font-lexendgiga text-2xl lg:text-3xl font-bold leading-7 gradient-text">
                Are you interested in concrete use cases and projects we are doing ? 
              </h2>
              <div className="flex justify-start items-start justify-items-center flex-row flex-wrap py-5 max-w-lg">
                <a
                  className="flex-view flex-row text-center text-sm"
                  href="mailto:info@syndata.co"
                  alt="send an email"
                >
                  <EmailIcon />
                  <span className="p-3">info@syndata.co</span>
                </a>
                <div
                  className="text-center flex-view flex-row text-sm"
                  alt="call us"
                >
                  <PhoneIcon />
                  <span className="p-3">+46 70 007 94 60</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <span className="uppercase font-lexendgiga text-sm text-black font-bold">
                Full Name
              </span>
              <input
                className={`w-full bg-white  border-gray text-pitch-black mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline border ${
                  errors.nameError ? "border-red-600 " : "border-none"
                }`}
                type="text"
                placeholder=""
                value={contactUsForm.name}
                onChange={(e) => {
                  setContactUsForm({ ...contactUsForm, name: e.target.value });
                  setIsDisabled(false);
                  setErrors({ ...errors, nameError: false });
                }}
              />
              {errors.nameError && (
                <small className="text-red-600 font-[9px]">
                  Name field required
                </small>
              )}
            </div>
            <div className="mt-5">
              <span className="uppercase font-lexendgiga  text-sm text-black font-bold">
                Email
              </span>
              <input
                id="email"
                className={`w-full bg-gray-300 text-pitch-black mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline border ${
                  errors.emailError ? "border-red-600 " : "border-none"
                }`}
                type="email"
                pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i"
                value={contactUsForm.email}
                required
                onChange={(e) => {
                  setContactUsForm({ ...contactUsForm, email: e.target.value });
                  setIsDisabled(false);
                  setErrors({ ...errors, emailError: false });
                }}
              />
              {errors.emailError && (
                <small className="text-red-600 font-[9px]">
                  Enter a valid email address
                </small>
              )}
            </div>
            <div className="mt-5">
              <span className="uppercase font-lexendgiga text-sm text-black font-bold">
                Message
              </span>
              <textarea
                value={contactUsForm.message}
                onChange={(e) => {
                  setContactUsForm({
                    ...contactUsForm,
                    message: e.target.value,
                  });
                  setIsDisabled(false);
                  setErrors({ ...errors, messageError: false });
                }}
                className={`resize-none w-full h-32 bg-white text-pitch-black mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline border ${
                  errors.messageError ? "border-red-600 " : "border-none"
                }`}
                required
              />
              {errors.messageError && (
                <small className="text-red-600 font-[9px]">
                  Message field required
                </small>
              )}
            </div>
            <div className="flex-view justify-start pt-2">
              <button
                className="bg-transparant hover:bg-orange hover:text-white text-black focus:outline-none focus:shadow-outline flex justify-center
                  items-center
                  gap-2 px-7 py-3
                  border
                  text-base max-lg:text-xs border-orange font-lexendgiga leading-none rounded-full disabled:opacity-25 disabled:cursor-none"
                onClick={handleSubmit}
                disabled={isDisabled}
                type="submit"
              >
                Send
              </button>
            </div>
          </div>
        </form>
        {loading && <Loading isloading={loading} />}
        <Modal
          title={modalContent.title}
          message={modalContent.message}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      </div>
    </div>
  );
};
