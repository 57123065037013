export const ShareIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 166 166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M135.567 60.8667C152.345 60.8667 166 47.2143 166 30.4333C166 13.6524 152.345 0 135.567 0C118.788 0 105.133 13.6524 105.133 30.4333C105.133 33.3577 105.571 36.178 106.345 38.8566L54.6689 64.6964C49.0203 57.1313 40.1791 52.5667 30.4333 52.5667C13.6524 52.5667 0 66.2191 0 83C0 99.7809 13.6524 113.433 30.4333 113.433C40.1935 113.433 49.0459 108.857 54.6761 101.308L106.345 127.143C105.571 129.822 105.133 132.642 105.133 135.567C105.133 152.348 118.788 166 135.567 166C152.345 166 166 152.348 166 135.567C166 118.786 152.345 105.133 135.567 105.133C125.666 105.133 116.879 109.909 111.318 117.254L59.6274 91.4081C60.4184 88.6958 60.8667 85.8924 60.8667 83C60.8667 80.1021 60.4176 77.2946 59.6298 74.5903L111.318 48.7449C116.879 56.0914 125.666 60.8667 135.567 60.8667ZM135.567 116.2C146.244 116.2 154.933 124.889 154.933 135.567C154.933 146.244 146.244 154.933 135.567 154.933C124.889 154.933 116.2 146.244 116.2 135.567C116.2 124.889 124.889 116.2 135.567 116.2ZM30.4333 102.367C19.7557 102.367 11.0667 93.6776 11.0667 83C11.0667 72.3224 19.7557 63.6333 30.4333 63.6333C37.8415 63.6333 44.4612 67.7321 47.7442 74.387C49.1083 77.0696 49.8 79.966 49.8 83C49.8 86.034 49.1083 88.9304 47.7146 91.6731C44.4612 98.2679 37.8415 102.367 30.4333 102.367ZM135.567 11.0667C146.244 11.0667 154.933 19.7557 154.933 30.4333C154.933 41.1109 146.244 49.8 135.567 49.8C124.889 49.8 116.2 41.1109 116.2 30.4333C116.2 19.7557 124.889 11.0667 135.567 11.0667Z"
      fill="black"
    />
  </svg>
);
