export const EmailIcon = () => (
    <svg
      width="30"
      height="30"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="clr-i-outline clr-i-outline-path-1"
        d="M32 6H4a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Zm-1.54 22H5.66l7-7.24-1.44-1.39L4 26.84V9.52l12.43 12.37a2 2 0 0 0 2.82 0L32 9.21v17.5l-7.36-7.36-1.41 1.41ZM5.31 8h25.07L17.84 20.47Z"
      />
      <path fill="none" d="M0 0h36v36H0z" />
    </svg>
  );
