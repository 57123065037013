import Lapland from "src/assets/images/lapland.png";
import TreeImg from "src/assets/images/tree-img.webp";
import TrainingModel from "src/assets/images/gifsImages/model-training.gif";
import { TeamValues } from "src/constants";
import { RegisterSection } from "src/pages/home/sections/RegisterSection";

export const About = () => (
  <section className="max-container">
    <article className="flex justify-between items-center max-lg:flex-col padding">
      <div className="flex flex-1 flex-col">
        <h2 className="font-lexendgiga text-[62px] font-bold max-lg:text-[42px]">
          <div className="rounded-full">
            The
            <div className="gradient-text hover:animate-fadeIn">
              Generative{" "}
            </div>
            Generation
          </div>
        </h2>
         <p className="font-raleway text-pitch-black text-xl max-lg:text-lg leading-10 sm:max-w-xl flex m-auto text-start max-lg:py-2">
           Our product is access. By enabling people to use their data, they will discover new things and share their insights. Our goal is knowledge.
         </p>
      </div>
      <div className="flex padding max-lg:px-0">
        <img
          src={TrainingModel}
          alt="About us"
          width={570}
          height={522}
          className="object-contain rounded-3xl"
        />
      </div>
    </article>
    <section className="flex justify-between items-center justify-items-center max-lg:flex-col gap-5 max-container h-[350px] w-3/4 max-lg:w-full FadeEffect">
      <div className="flex-view flex-col text-center sm:max-w-2xl padding">
        <h2 className="title-text">Our Purpose</h2>
        <p className="mt-4 lg:max-w-lg info-text">
          We think data should be easy to use, easier to understand, and safe
          for all. We want our customers to realize that innovation is possible
          within regulation, privacy and usefulness can co-exist, and the
          democritization of the data economy is coming.
        </p>
      </div>
      <div className="flex-view mb-3">
        <img
          src={Lapland}
          alt="Lapland"
          width={470}
          height={430}
          className="object-contain max-lg:hidden"
        />
      </div>
    </section>
    {/* <Team /> commenting out Team until further team increase*/}
    <section className="padding flex flex-col">
      <h1 className="title-text text-center">Our Values</h1>
      <article data-aos="fade-down" className="flex justify-between items-center max-lg:flex-col">
        <div className="flex padding max-lg:px-0">
          <img
            src={TreeImg}
            alt="TreeImg"
            width={470}
            height={522}
            className="object-contain"
          />
        </div>
        <div className="items-center">
          {TeamValues.map((item) => (
            <div key={item.title} className="flex-1">
              <h1 className="font-bold text-orange font-lexendgiga">{item.title}</h1>
              <p className="sm:max-w-2xl font-raleway">{item.description}</p>
            </div>
          ))}
        </div>
      </article>
    </section>
    <div className="padding">
      <RegisterSection/>
    </div>
  </section>
);
