import { Button } from "src/components/Button";

export const Modal = ({ title, message, isOpen, onClose }) => (
    <>
    {isOpen && (
      <div className="fixed inset-0 flex items-center justify-center z-10 overflow-auto">
        <div className="modal-overlay bg-gray opacity-50 fixed inset-0"></div>
        <div className="modal-container w-[600px] z-50 ">
          <div className="bg-lite-pink rounded-3xl shadow-3xl p-8 m-auto padding-x">
            <h2 className="text-2xl font-semibold font-lexendgiga text-center mb-4">
              {title}
            </h2>
            <p className="font-raleway text-center text-pitch-black m-10">{message}</p>
            <div className="mt-6 w-1/3 m-auto shadow-3xl">
              <Button bgColor="orange" label="OK" onClick={onClose}/>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  );

